import {Paper} from '@mantine/core';

let InfoBlock = () => {
    return (
        <div className='info-block'>
            <h3 style={{marginBottom: "8px"}}>
                Who am I?
            </h3>
            <p style={{textIndent:"25px"}}>
                NYC based Fullstack Software Engineer with {new Date().getFullYear() - 2015} years in the Finance
                Technology space. I bring a wealth of domain expertise to the financial advisor industry, and my diverse
                experience ensures I deliver functional and robust software.
            </p>
            <p style={{textIndent:"25px", marginTop: "12px"}}>
                My commitment to innovation and problem solving doesn't stop when I leave the office - I've been a
                coding hobbyist for {new Date().getFullYear() - 2009} years, working on multiple side projects in many
                forms - game development, public APIs, open source software, and random tooling utilities.
            </p>
        </div>

    )
}

export default InfoBlock
