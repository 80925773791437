import {Timeline, Text, useMantineTheme} from '@mantine/core';
import { IconGitBranch, IconGitPullRequest, IconGitCommit, IconMessageDots } from '@tabler/icons-react';

let WorkHistory = () => {
    return (
        <Timeline active={0} bulletSize={12} lineWidth={4}>
            <Timeline.Item  title="Full-Stack Software Engineer @ SmartAsset" lineVariant="dashed" bullet={<div className='bullet'/>}>
                <Text size="sm">
                    After returning to SmartAsset, I am currently a key contributor to the in-house SSO platform. I implement secure back-end authorization systems and collaborate with our UX team to deliver modern front-end views, while mentoring junior developers.
                </Text>
                <Text color="dimmed" size="xs" mt={4}>OCT 2022 - PRESENT</Text>
            </Timeline.Item>

            <Timeline.Item  title="Software Engineer @ Truepill" lineVariant="dashed">
                <Text size="sm">
                    During my short stay at Truepill (due to company-wide layoffs) I led the creation of in-house tooling for bug tracking, and initiatives in improving development practices - such as the adoption of TDD and BDD to the team.
                </Text>
                <Text color="dimmed" size="xs" mt={4}>MAR 2022 - OCT 2022</Text>
            </Timeline.Item>

            <Timeline.Item title="Software Engineer @ SmartAsset" lineVariant="dashed">
                <Text size="sm">
                    After recieving a return offer from SmartAsset post-graduation I contributed to multiple company revenue incentives by iterating site content based on AB test results, while managing and improving incident response processes.
                </Text>
                <Text color="dimmed" size="xs" mt={4}>SEP 2019 - MAR 2022</Text>
            </Timeline.Item>

            <Timeline.Item title="Software Engineer Intern @ SmartAsset" lineVariant="dashed">
                <Text size="sm">
                    While taking classes at New York Institute of Technology, I worked for SmartAsset as a full-time intern where I performed data cleaning and analysis on SEC data for sales outreach initiatives, and developed an ad campaign report generator for our Ad Ops team.
                </Text>
                <Text color="dimmed" size="xs" mt={4}>JUN 2016 - SEP 2019</Text>
            </Timeline.Item>
        </Timeline>
    );
}

export default WorkHistory
